import {
  IServerSideDatasource,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest
} from 'ag-grid-community/dist/lib/interfaces/iServerSideDatasource'
import { RequestBuilder } from '@/core/infrastructure/components/Grid/infrastructure/service/RequestBuilder'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { APIDataSource } from '@/components/InterfaceEditor/components/basic/AnalyticTable/api/DataSource'
import PivotColumnsBuilder from '@/core/infrastructure/components/Grid/infrastructure/service/PivotColumnsBuilder'
import { Nullable } from '@/core/domain/type/types'

export class DataWarehouseSource implements IServerSideDatasource {
  private readonly entityId: number
  private externalFilters: Nullable<object[]>
  private readonly type: string
  private requestTypes = {
    extended_object: APIDataSource.ExtendedObjectQuery,
    query: APIDataSource.QueryQuery
  }
  private lastPayload: object

  constructor (entityId: number, type: string, externalFilters: Nullable<object[]> = null) {
    this.entityId = entityId
    this.externalFilters = externalFilters
    this.type = type
  }

  setExternalFilters (externalFilters: Nullable<object[]>) {
    this.externalFilters = externalFilters
  }
  getExternalFilters (): object[] {
    return this.externalFilters
  }
  getLastPayload (): object {
    return this.lastPayload
  }
  getRows (params: IServerSideGetRowsParams) {
    const pivotColumnIsXref = params.request.pivotCols.length > 0
      ? params.columnApi.getColumn(params.request.pivotCols[0].id).getUserProvidedColDef().type.includes('xrefField')
      : false
    const payload = RequestBuilder.build(params.request, pivotColumnIsXref, this.externalFilters)
    this.lastPayload = payload
    APIClient.shared.request(new this.requestTypes[this.type](this.entityId, payload))
      .then((response: {data, pivot_fields}) => {
        let data
        let pivotFields
        if (params.request.pivotMode && params.request.pivotCols.length > 0) {
          data = response.data
          pivotFields = response.pivot_fields
          if (params.request.groupKeys.length === 0) {
            PivotColumnsBuilder.build(params.request, pivotFields, params.columnApi)
          }
        } else {
          params.columnApi.setPivotResultColumns([])
          data = response
        }
        params.success({ rowData: data, rowCount: this.getLastRow(params.request, data.length) })
      })
      .catch((error) => {
        params.fail()
        throw error
      })
  }

  private getLastRow (request: IServerSideGetRowsRequest, resultLength: number) {
    if (resultLength === 0) {
      return request.startRow
    }

    const currentLastRow = request.startRow + resultLength + 1

    return currentLastRow <= request.endRow ? (currentLastRow - 1) : -1
  }

  getType (): string {
    return this.type
  }
}
