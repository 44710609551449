<template>
  <div v-show="!isHidden" style="width: 100%; height: 100%">
    <div>
      <el-button
        v-show="this.isEditor()"
        :type="isSaveFilter ? 'info' : 'warning'"
        size="small"
        style="position: absolute; bottom: 4px; right: 290px;z-index: 2;"
        @click="saveFilterModel"
      >
        {{ $t('main.button.save_filter') }}
      </el-button>
    </div>

    <div class="list_toolbar" v-show="isShowBtnRefresh" :style="styleContainerBtnRefresh">
      <el-button
        type="text"
        icon="el-icon-refresh-right"
        class="size"
        @click="refreshGrid"
        :style="styleBtnRefresh"
      ></el-button>
    </div>

    <grid
      ref="grid"
      :key="key"
      :columns="columns"
      :pagination="pagination"
      :data-source-service="dataSourceService"
      :multi-selection="multiSelection"
      :check-box-selection="checkBoxSelection"
      :is-pivot-mode="isPivotMode"
      :filterModel="filterModel"
      :sideBar="sideBar"
      :closeToolPanel="closeToolPanel"
      :floatingFilter="floatingFilter"
      :groupUseEntireRow="groupUseEntireRow"
      :readonly="readonly"
      :disabled-column-header="disabledColumnHeader"
      :hide-header="hideHeader"
      :wrap-header="wrapHeader"
      :theme="theme"
      :context-menu="getContextMenu()"
      @grid-ready="isGridReady = true"
    ></grid>

    <slot></slot>
  </div>
</template>

<script>
import Grid from '@/core/infrastructure/components/Grid/index.vue'
import mixin from '@/components/InterfaceEditor/components/mixins'
import VisibleMixin from '@/components/InterfaceEditor/components/visible_properties_mixin'
import { DataWarehouseSource } from '@/components/InterfaceEditor/components/basic/AnalyticTable/DataSources/DataWarehouseSource'
import FilterBuilder, { EComponentTypes } from '../../utils'

export default {
  name: 'AnalyticTable',

  components: { Grid },

  mixins: [mixin, VisibleMixin],

  inject: {
    isEditor: {
      default: () => false
    }
  },

  props: {
    editorAlias: {
      type: String,
      description: 'Псевдоним'
    },

    source: {
      type: Object,
      editor: 'AnalyticTableSource',
      default: () => {
        return {
          type: null,
          entityId: null
        }
      }
    },

    columns: {
      description: 'Столбцы',
      type: Array,
      default: () => [],
      editor: 'TableColumns',
      options: {
        sourceType: 'external'
      }
    },

    hideExport: {
      type: Boolean,
      description: 'Скрыть экспорт в контекстном меню',
      default: false
    },

    checkBoxSelection: {
      type: Boolean,
      description: 'Чек-бокс выделение',
      default: false
    },

    multiSelection: {
      type: Boolean,
      description: 'Мульти-выбор',
      default: false
    },

    pagination: {
      type: Boolean,
      description: 'Пагинация',
      default: true
    },

    sideBar: {
      type: Boolean,
      description: 'Скрыть боковую панель',
      default: false
    },

    floatingFilter: {
      type: Boolean,
      description: 'Фильтры столбцов',
      default: false
    },

    groupUseEntireRow: {
      type: Boolean,
      description: 'Группировать по всей строке',
      default: false
    },

    closeToolPanel: {
      type: Boolean,
      description: 'Свернуть боковую панель',
      default: true
    },

    theme: {
      editor: 'Select',
      description: 'Стили таблицы',
      default: 'ag-theme-alpine',
      options: {
        multiple: false,
        options: [
          { name: 'Цвет: светлый; Высота: с отступами', id: 'ag-theme-alpine' },
          { name: 'Цвет: светлый; Высота: минимальная', id: 'ag-theme-balham' },
          { name: 'Цвет: темный; Высота: с отступами', id: 'ag-theme-alpine-dark' },
          { name: 'Цвет: темный; Высота: минимальная', id: 'ag-theme-balham-dark' }
        ]
      }
    },

    disabledColumnHeader: {
      type: Boolean,
      default: false,
      description: 'Заблокировать шапку'
    },

    hideHeader: {
      type: Boolean,
      default: false,
      description: 'Скрыть шапку'
    },

    wrapHeader: {
      type: Boolean,
      default: false,
      description: 'Перенос текста в шапке'
    },

    alwaysActive: {
      type: Boolean,
      description: 'Всегда активно'
    },

    isShowBtnRefresh: {
      type: Boolean,
      description: 'Показать кнопку обновить',
      default: false
    },

    styleContainerBtnRefresh: {
      type: String,
      description: 'CSS стили тулбара',
      default: 'background: white;'
    },

    styleBtnRefresh: {
      type: String,
      description: 'CSS стили кнопки обновить',
      default: 'font-size: 18px;'
    },

    filters: {
      type: Array,
      editor: 'Filters',
      options: {
        showXrefOption: true,
        showEqualsTypes: true
      }
    },

    initialColumnState: {
      type: Array,
      frozen: true
    },

    filterModel: {
      // type: Object,
      // default: () => { return { } },
      frozen: true
    },
    // из configurator открытие модального окна, чтобы сохранить вид таблицы (columnState)
    openModalWindowByConfigurator: {
      type: Boolean,
      frozen: true
    },

    isPivotMode: {
      type: Boolean,
      frozen: true
    }
  },

  data () {
    return {
      dataSourceService: null,
      isGridReady: false,
      isComponentReady: false,
      filterDebounce: undefined,
      dataFilter: null,
      isSaveFilter: false,
      saveStateColumn: null
    }
  },

  beforeMount () {
    this.init()
  },

  computed: {
    dataFilters () {
      const builder = new FilterBuilder(
        this.filters,
        this.getModel(),
        this.$store,
        EComponentTypes.analyticalTable
      )

      return builder.buildAsApiQl()
    },

    key () {
      return `${this.source.type}_${this.source.entityId}`
    },

    columnState () {
      if (this.isGridReady && this.$refs.grid.columnApi) {
        // console.log('%c%s', 'color: yellow;', 'AnalyticTable columnState')

        return this.$refs.grid.columnApi.getColumnState()
      }

      return []
    },

    pivotMode () {
      if (this.isGridReady && this.$refs.grid.columnApi) {
        // console.log('%c%s', 'color: yellow;', 'AnalyticTable pivotMode')
        return this.$refs.grid.columnApi.isPivotMode()
      }

      return false
    },

    getFilterModel () {
      // console.log('%c%s', 'color: yellow;', 'AnalyticTable getFilterModel')
      if (this.isGridReady) {
        return this.$refs.grid.gridApi.getFilterModel()
      }

      return {}
    }
  },

  methods: {
    getContextMenu () {
      const subMenu = [
        'csvExport',
        'excelExport'
      ]

      if (!this.hideExport) {
        subMenu.push('separator')
        subMenu.push({
          name: 'Экспорт всей таблицы',
          action: () => {
            this.dynamicExport()
          }
        })
      }

      return [
        'copy',
        'copyWithHeaders',
        'paste',
        'separator',
        'chartRange',
        {
          name: 'Экспорт',
          icon: '<span class="el-icon-download"></span>',
          subMenu
        }
      ]
    },

    dynamicExport (item = null) {
      if (!this.columns.length || !this.source.entityId || !this.source.type) {
        return
      }

      const { where = null } = this.dataSourceService.getLastPayload()

      const fields = this.columns.map(({ field, columnType, headerName }) => {
        return {
          field,
          type: columnType.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`),
          name: headerName
        }
      })

      const title = this.$t('registry.message_export.title')
      const message = !item
        ? this.$t('registry.message_export.message')
        : `${this.$t('registry.message_export.task_message')} "${item.task_name}"?`

      this.$confirm(message, title, {
        confirmButtonText: this.$t('main.button.ok'),
        cancelButtonText: this.$t('main.button.cancel'),
        type: 'warning'
      }).then(() => {
        const body = {
          fields
        }

        if (where !== null) {
          body.payload = {
            where
          }
        }

        this.$http({
          method: 'post',
          url: !item
            ? `${this.$config.api}/etleditor/export_source/${this.source.type}/${this.source.entityId}`
            : `${this.$config.api}/etleditor/export/${item.task_id}`,
          responseType: 'arraybuffer',
          data: body
        })
          .then(response => {
            let blob = new Blob([response.data], { type: response.headers['content-type'] })
            let url = window.URL.createObjectURL(blob)
            window.open(url)
          })
      })
    },

    saveFilterModel () {
      // console.log('%c%s', 'color: yellow;', 'AnalyticTable saveFilterModel')
      this.isSaveFilter = !this.isSaveFilter
      this.dataFilter = this.$refs.grid.getFilterModel()
    },

    init () {
      // console.log('%c%s', 'color: yellow;', 'AnalyticTable init')
      if (!this.source.type || !this.source.entityId) {
        return false
      }
      switch (this.source.type) {
        case 'extended_object':
        case 'query':
          this.dataSourceService = new DataWarehouseSource(this.source.entityId, this.source.type, this.dataFilters)
          break
      }
    },

    refreshGrid () {
      this.$refs.grid.load()
    }
  },

  watch: {
    openModalWindowByConfigurator: {
      handler (value) {
        if (value) {
          this.saveStateColumn = this.$refs.grid.columnApi.getColumnState()
        } else {
          const colIdState = this.saveStateColumn.map((state) => ({
            colId: state.colId
          }))
          this.$refs.grid.columnApi.applyColumnState({ state: colIdState, applyOrder: true })
        }
      }
    },
    dataFilters () {
      // console.log('%c%s', 'color: yellow;', 'AnalyticTable dataFilters')
      if (this.filterDebounce) {
        clearTimeout(this.filterDebounce)
      }
      this.filterDebounce = setTimeout(() => {
        this.dataSourceService.setExternalFilters(this.dataFilters)
        this.$refs.grid.load()
      }, 300)
    },

    key () {
      this.isGridReady = false
      this.isComponentReady = false
      this.$emit('change-property', { name: 'initialColumnState', value: [] })
      this.$emit('change-property', { name: 'filterModel', value: {} })
      this.$emit('change-property', { name: 'isPivotMode', value: false })
      this.init()
    },

    dataFilter (value) {
      // console.log('%c%s', 'color: yellow;', 'AnalyticTable dataFilter', value)
      if (this.isEditor() && this.isComponentReady) {
        this.$emit('change-property', { name: 'filterModel', value })
      }
    },

    isGridReady (value) {
      // console.log('%c%s', 'color: yellow;', 'AnalyticTable isGridReady', value)
      if (value && this.initialColumnState) {
        // Внешнее состояние таблицы
        this.$refs.grid.columnApi.applyColumnState({ state: this.initialColumnState, applyOrder: true })
        if (this.isPivotMode) {
          this.$refs.grid.columnApi.setPivotMode(true)
        }
        this.isComponentReady = true
      } else if (value && (this.initialColumnState || []).length === 0) {
        this.isComponentReady = true
      }
      if (this.filterModel && Object.keys(this.filterModel).length) {
        this.$refs.grid.gridApi.setFilterModel(this.filterModel)
      }
    },

    columnState (value) {
      // console.log('%c%s', 'color: yellow;', 'AnalyticTable columnState', value)
      if (this.isEditor() && this.isComponentReady) {
        this.$emit('change-property', { name: 'initialColumnState', value })
      }
    },

    pivotMode (pivot) {
      // console.log('%c%s', 'color: yellow;', 'AnalyticTable pivotMode', pivot)
      if (pivot) this.$refs.grid.load()
      if (this.isEditor() && this.isComponentReady) {
        this.$emit('change-property', { name: 'isPivotMode', value: pivot })
      }
    },

    getFilterModel (value) {
      // console.log('%c%s', 'color: yellow;', 'AnalyticTable getFilterModel', value)
      if (this.isEditor() && this.isComponentReady) {
        this.$emit('change-property', { name: 'filterModel', value })
      }
    }
  }
}
</script>

<style scoped>

</style>
